import React from 'react'
import Fade from 'react-reveal/Fade';

import './Work.css'

const Jobs = () => {
    return (
        <>
        <div className='work submenu'>

                <div className="work-content">
                    <Fade top>
                    <h1>MY WORK</h1>
                    </Fade>
                </div>
            </div>
        </>
    )
}

export default Jobs