import React from 'react'
import Me from "../../assets/me.webp";
import Zoom from 'react-reveal/Zoom';
import './Resume.css'

const Resume = () => {
  return (
    <>
            <div className='resume container' name='About'>
              <div className='resume-content'>

                      <div className='resume-header'>
                        <Zoom duration={2000}>
                        <h1>Animation Experience</h1>
                        </Zoom>
                        <p> Below, you will find a collection of mywork spanning various projects,
                            showcasing myskills in 2D and 3D animation, graphic design, and motion
                            graphics. With a strong foundation in both traditional and digital techniques, |
                            have contributed to numerous successful projects in the film and television
                            industry. Every piece showcases mycommitment to storytelling, technical
                            accuracy, and creative innovation.
                        </p>
                      </div>

                <div className='resume-flex'>

                  <div className='resume-left'>

                    <div className='workExperience'>
                      <h1>Previs/Postvis Artist / Animator| FEB 2021- SEP 2022</h1>
                      <h2>Proof Inc, Los Angeles CA - Remote</h2>
                      <ul>
                          <li> Projects included Monarch, Shape Island, Chupa, Crater, and Morbius</li>
                          <li>Animated various characters, creatures, props and laid camera work for several shots and sequences</li>
                          <li> Performed compositing work required on some of the shots</li>
                        </ul>
                    </div>

                    <div>
                        <h1>Previs Artist / Animator | NOV 2020- FEB 2021</h1>
                        <h2>Halon Entertainment LLC, Santa Monica, CA</h2>
                        <ul>
                          <li>  Project included Disney’s Pinnochio</li>
                          <li>  Animated various characters, creatures, props for several shots and sequences</li>
                          <li>  Did a lot of camera lay out</li>
                          <li>  Created 3D animated scenes to show the clients what their shot would
                          look like before filming it.</li>
                          <li> Integrated animation sequences from Maya to Unreal Engine</li>
                        </ul>
                    </div>





                  </div>



                  <div className='resume-right'>
                    <div>
                      <img src={Me} alt="activities" />
                    </div>
                  </div>

          </div>  
      </div>



                <div className='resume-more'>

                <div className='workExperience'>
                      <h1>Previs Artist / Animator | FEB 2020- SEP 2020</h1>
                        <h2>Proof Inc, Los Angeles CA - Remote</h2>
                        <ul>
                          <li>  Projects included The Tomorrow War</li>
                          <li>  Animated various characters, creatures, props and laid camera work for
                          several shots and sequences</li>
                          <li>  Did effects work required on some of the shots</li>
                          <li>  Created 3D animated scenes to show the clients what their shot would
                          look like before filming it.</li>
                          <li> Integrated animation sequences from Maya to Unreal Engine</li>
                        </ul>
                    </div>


                    <div className='workExperience'>
                          <h1>Previs/Postvis Artist / Animator | NOV 2018- DEC 2019</h1>
                          <h2>Halon Entertainment LLC, Santa Monica, CA</h2>
                          <ul>
                              <li>  Projects included X-Men Dark Phoenix, Ford v Ferrari and The Call of
                              the Wild</li>
                              <li>  Animated various characters, creatures, props and laid camera work for
                              several shots and sequences</li>
                              <li>  Did effects work required on some of the shots</li>
                              <li>  Created 3D animated scenes to show the clients what their shot would
                              look like before filming it.</li>
                              <li> Integrated animation sequences from Maya to Unreal Engine</li>
                          </ul>
                      </div>
                </div>
          </div>

    </>
  )
}

export default Resume

