import React from 'react';

import ImageSlider from './ImageSlider';
// import Work1 from "../../assets/me.png";
// import Work2 from "../../assets/me2.png";
// import Work3 from "../../assets/me3.png";
// import Work4 from "../../assets/maya.webp";
// import Work5 from '../../assets/maya.webp'


const Section4 = () => {
  const Work = [
    // Work1,
    // Work2,
    // Work3,
    // Work4,
    // Work5

  ];

  return (
          <div className='section4'>
            <ImageSlider images={Work} />
          </div>
  );
}

export default Section4

