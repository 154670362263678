import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import './NavbarStyles.css'
// import { Link as LinkRoll } from 'react-scroll'
// import {Link} from 'react-router-dom'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)

    return (
        <>
        <div className='navbar' name='top'>
            <div className="navbar-container">
                
                 {/* <div className="navbar-logo">
                    <Link to='/'>
                        <h1>ALWYN FUNA</h1>
                    </Link>
                </div> */}
{/* 
                <ul className={nav ? 'nav-menu active' : 'nav-menu'}>

                    <LinkRoll activeClass="active" to="Work" spy={true} smooth={true} duration={500} >
                    <li>My Work</li>
                    </LinkRoll>

                    <LinkRoll activeClass="active" to="About" spy={true} smooth={true} duration={500} >
                    <li>About</li>
                    </LinkRoll>

                    <LinkRoll activeClass="active" to="Contact" spy={true} smooth={true} duration={500} >
                    <li>Hire Me!</li>
                    </LinkRoll>
                </ul> */}

                <div className="hamburger" onClick={handleNav}>
                    {!nav ? (<FaBars className='icon' />) : (<FaTimes className='icon' />)}
                </div>
            </div>
        </div>
        </>
    )
}

export default Navbar
