import React from 'react'
import Cards from '../servicesCards/Cards';
import ContactForm from '../contact/ContactForm';
import Software from '../software/SoftwareSection';
import Resume from '../resume/Resume';
import Gallery from '../imageGallery/ImageGalleryProps';


const home = () => {
  return (
    <>
    <Cards />
    <Gallery />
    <Software />
    <Resume />
    <ContactForm />
    </>
  )
}

export default home
