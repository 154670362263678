import React from 'react';
import ImageGallery from './ImageGallery';
import image1 from '../../assets/barbarian_1.webp';
import image2 from '../../assets/barbarian_2.webp';
import image3 from '../../assets/orc_A.webp';
import image4 from '../../assets/drawing_1.webp';



const Gallery = () => {

    const images = [
        image1,
        image2,
        image3,
        image4
        // Add more image URLs here
      ];


    return (
        <>
  
            <div >
            <ImageGallery images={images} />
            </div>
  
  
        </>
      )
  }
  
  export default Gallery
