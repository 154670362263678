import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import ContactPage from './routes/ContactPage'
import Work from './routes/WORK';


ReactDOM.render(
  <BrowserRouter basename={window.location.pathname || ''} >
    <Routes>

      <Route exact path='/' element={<App />} />
      <Route exact path='/work' element={<Work />} />
      <Route exact path='/contact' element={<ContactPage />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

