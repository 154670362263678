import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Work from '../components/work/Work'
import MyWorkSection from '../components/work/MyWorkSection'
import Section4 from '../components/section4/Section4';


const MyWork = () => {
  return (
    <>
    <Navbar /> 
    <Work />
    <Section4 />
    <MyWorkSection/>    

<Footer />
     </>
  )
}

export default MyWork