import React from 'react'
import { FaImdb } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import Zoom from 'react-reveal/Zoom';


// import { Link } from 'react-router-dom';
// import Fade from 'react-reveal/Fade';
import './Hero2.css'

// import Me from '../../assets/me3.png'

const Hero2 = () => {
    return (
<>        

        <div className='hero'>
            <div className='hero-left'>
            </div>

            <div className='hero-right'>
            </div>


            <div className="hero-container">

                {/* <div className='me'>
                    <img src={Me} alt='Alwyn Funa Headshot' loading='lazy' />
                </div> */}

                <div className="content">
                        <Zoom duration={2000}>
                        <h1>Alwyn Funa</h1>
                        </Zoom>
                        <h2>Animator and Graphic Designer</h2>
                        <div className='socials'>
                            <a href="https://www.linkedin.com/in/alwyn-funa/" target="_blank" rel="noreferrer">  <p><FaLinkedinIn /></p></a>
                            <a href="https://www.imdb.com/name/nm3215245/" target="_blank" rel="noreferrer">  <p><FaImdb  /></p></a>

                        </div>
                </div>
            </div>
        </div>


</>

    )
}

export default Hero2